import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { Typography } from "@mui/material"

import { pageSetting, theme } from "../helpers/theme"

import content from "../helpers/content.json"
import SectionTitle from "../components/SectionTitle"

const Aboutus = () => {
  const title = content["about-us"].title
  const text = content["about-us"].content
  return (
    <Layout>
      <Seo title="O nas" />
      <div css={styles.root}>
        <SectionTitle title={title} />
        <Typography
          sx={{
            textAlign: "justify",
            ...pageSetting.text2,
            fontSize: "1.2rem",
          }}
        >
          {text}
        </Typography>
      </div>
    </Layout>
  )
}

const styles = {
  root: {
    marginTop: theme.gutterBig * 6,
    ...pageSetting.text2,
  },
}

export default Aboutus
